import React, { useEffect, useState } from 'react';
import { Leaf, Heart, TreePine, MessageCircle } from 'lucide-react';

export const SplashScreen: React.FC = () => {
  const [show, setShow] = useState(true);
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShow(false);
    }, 4000);

    // Progress bar animation
    const interval = setInterval(() => {
      setProgress(prev => Math.min(prev + 1, 100));
    }, 40);

    return () => {
      clearTimeout(timer);
      clearInterval(interval);
    };
  }, []);

  if (!show) return null;

  return (
    <div className="fixed inset-0 bg-gradient-to-br from-green-50 to-white z-50 flex flex-col items-center justify-center overflow-hidden">
      {/* Floating icons background */}
      <div className="absolute inset-0 overflow-hidden">
        {[...Array(20)].map((_, i) => (
          <div
            key={i}
            className="absolute animate-float"
            style={{
              left: `${Math.random() * 100}%`,
              top: `${Math.random() * 100}%`,
              animationDelay: `${Math.random() * 2}s`,
              opacity: 0.1
            }}
          >
            {i % 4 === 0 && <Leaf className="h-6 w-6 text-green-400" />}
            {i % 4 === 1 && <Heart className="h-6 w-6 text-red-400" />}
            {i % 4 === 2 && <TreePine className="h-6 w-6 text-green-600" />}
            {i % 4 === 3 && <MessageCircle className="h-6 w-6 text-blue-400" />}
          </div>
        ))}
      </div>

      {/* Main content */}
      <div className="relative z-10 flex flex-col items-center">
        <div className="animate-bounce-slow relative">
          <div className="absolute inset-0 animate-ping-slow rounded-full bg-green-200 opacity-75"></div>
          <Leaf className="h-20 w-20 text-green-500 relative z-10 transform hover:scale-110 transition-transform" />
        </div>
        
        <h1 className="mt-8 text-4xl font-bold text-green-600 animate-fade-in tracking-wider">
          Green Message
        </h1>
        
        <p className="mt-4 text-lg text-gray-600 animate-fade-in-delay text-center max-w-md">
          Spreading love through blockchain 🌱
        </p>

        {/* Progress bar */}
        <div className="mt-12 w-64 h-1 bg-gray-200 rounded-full overflow-hidden">
          <div 
            className="h-full bg-green-500 transition-all duration-300 ease-out"
            style={{ width: `${progress}%` }}
          />
        </div>
        
        <p className="mt-4 text-sm text-gray-500 animate-pulse">
          Loading your green experience...
        </p>
      </div>
    </div>
  );
}; 