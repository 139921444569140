import React, { useState } from 'react';
import { Bug, Leaf, Share2, Trash2, Copy, TwitterIcon, MessageCircle } from 'lucide-react';
import type { Message } from '../types';
import { useStore } from '../store/useStore';
import { RECIPIENT_ADDRESS } from '../utils/constants';

const ADMIN_ADDRESS = '0xD0d7b2e7c46C28610EbA26b8B754cC3203109FAb';

interface MessageItemProps {
  message: Message;
  index: number;
}

export const MessageItem: React.FC<MessageItemProps> = ({ message, index }) => {
  const { likeMessage, connectedAddress, currentChain } = useStore();
  const [isLiking, setIsLiking] = useState(false);
  const isAdmin = connectedAddress?.toLowerCase() === ADMIN_ADDRESS.toLowerCase();
  const [showSharePopup, setShowSharePopup] = useState(false);

  const handleDelete = async () => {
    if (!isAdmin) return;
    
    if (window.confirm('Are you sure you want to delete this message?')) {
      try {
        // TODO: Add Firebase delete functionality later
        alert('Message deleted successfully');
      } catch (error) {
        console.error('Error deleting message:', error);
        alert('Failed to delete message');
      }
    }
  };

  const handleLike = async () => {
    if (!connectedAddress || isLiking) return;
    setIsLiking(true);
    try {
      await likeMessage(message.id, connectedAddress, currentChain);
    } catch (error) {
      console.error('Error liking message:', error);
    } finally {
      setIsLiking(false);
    }
  };

  const handleShare = () => {
    setShowSharePopup(true);
  };

  const handleCopy = async () => {
    const text = `Check out this message on greenmessage: "${message.content}"`;
    const url = window.location.href;
    await navigator.clipboard.writeText(`${text}\n${url}`);
    setShowSharePopup(false);
  };

  const handleTwitterShare = () => {
    const text = encodeURIComponent(`Check out this message on greenmessage: "${message.content}"`);
    const url = encodeURIComponent(window.location.href);
    window.open(`https://twitter.com/intent/tweet?text=${text}&url=${url}`, '_blank');
    setShowSharePopup(false);
  };

  const handleTelegramShare = () => {
    const text = encodeURIComponent(`Check out this message on greenmessage: "${message.content}"\n${window.location.href}`);
    window.open(`https://t.me/share/url?url=${text}`, '_blank');
    setShowSharePopup(false);
  };

  const ThemeIcon = message.theme === 'butterfly' ? Bug : Leaf;
  const hasLiked = Boolean(connectedAddress && message.likedBy?.includes(connectedAddress));
  const likeButtonTitle = !connectedAddress 
    ? "Connect wallet to like" 
    : hasLiked 
      ? "Already liked" 
      : "Like (0.001 BNB)";

  return (
    <div className="relative group">
      <div className={`flex items-start space-x-3 p-4 rounded-lg transition-colors ${
        index % 2 === 0 
          ? 'bg-green-50 border border-green-100 hover:bg-green-100' 
          : 'bg-gray-50 border border-gray-100 hover:bg-gray-100'
      }`}>
        <ThemeIcon className={`h-5 w-5 ${
          index % 2 === 0 ? 'text-green-500' : 'text-gray-500'
        } animate-bounce`} />
        <div className="flex-1">
          <div className="flex flex-col space-y-1">
            <div className="flex items-center justify-between">
              <span className="font-medium text-gray-900">
                {message.isAnonymous ? 'Anonymous' : message.sender}
              </span>
              <span className={`text-sm font-medium ${
                index % 2 === 0 ? 'text-green-600' : 'text-gray-600'
              }`}>
                {((message as unknown as { giftType?: string }).giftType || 'Green Message')?.charAt(0).toUpperCase() + 
                 ((message as unknown as { giftType?: string }).giftType || 'Green Message')?.slice(1)}
              </span>
            </div>
            {(message as unknown as { recipient: string }).recipient !== RECIPIENT_ADDRESS[message.chain as keyof typeof RECIPIENT_ADDRESS] && (
              <span className="text-sm text-gray-600">
                To: {(message as unknown as { recipient: string }).recipient}
              </span>
            )}
          </div>
          <p className="mt-2 text-gray-600">{message.content}</p>
          <div className="flex justify-between items-center mt-2">
            <div className={`text-sm ${
              index % 2 === 0 ? 'text-green-600' : 'text-gray-600'
            }`}>
              Sent {message.amount} {message.chain}
            </div>
            <time className="text-xs text-gray-400">
              {new Date(message.timestamp).toLocaleString('en-GB', {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric',
                hour: '2-digit',
                minute: '2-digit',
                hour12: false
              })}
            </time>
          </div>
        </div>
      </div>
      
      <div className="flex items-center space-x-3 mt-2">
        <div className="flex items-center space-x-2">
          <button
            onClick={handleLike}
            disabled={!connectedAddress || isLiking || hasLiked}
            title={likeButtonTitle}
            className={`flex items-center space-x-1 text-sm ${
              !connectedAddress 
                ? 'text-gray-400 cursor-not-allowed' 
                : hasLiked 
                  ? 'text-green-600' 
                  : 'text-gray-500 hover:text-green-600'
            }`}
          >
            <Leaf className={`h-4 w-4 ${hasLiked ? 'animate-bounce' : ''}`} />
            <span>{message.likes}</span>
          </button>

          <button
            onClick={handleShare}
            className="flex items-center space-x-1 text-sm text-gray-500 hover:text-gray-600"
            title="Share message"
          >
            <Share2 className="h-4 w-4" />
            <span>Share</span>
          </button>

          <button
            onClick={handleCopy}
            className="flex items-center space-x-1 text-sm text-gray-500 hover:text-gray-600"
            title="Copy message"
          >
            <Copy className="h-4 w-4" />
            <span>Copy</span>
          </button>

          {isAdmin && (
            <button
              onClick={handleDelete}
              className="flex items-center text-sm text-gray-500 hover:text-red-600 transition-colors"
              title="Delete message (Admin only)"
            >
              <Trash2 className="h-4 w-4" />
            </button>
          )}
        </div>
      </div>
      {showSharePopup && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50" onClick={() => setShowSharePopup(false)}>
          <div className="bg-white rounded-lg p-6 max-w-sm w-full mx-4 shadow-xl" onClick={e => e.stopPropagation()}>
            <div className="flex items-center space-x-3 mb-4">
              <Share2 className="h-6 w-6 text-green-500" />
              <h3 className="text-lg font-semibold text-gray-900">Share Message</h3>
            </div>
            
            <div className="space-y-4">
              <button
                onClick={handleCopy}
                className="w-full flex items-center space-x-4 p-4 hover:bg-gray-50 rounded-lg transition-colors border border-gray-100"
              >
                <Copy className="h-6 w-6 text-gray-500 flex-shrink-0" />
                <div className="text-left flex-1">
                  <div className="font-medium text-gray-900">Copy Link</div>
                  <div className="text-sm text-gray-500 mt-0.5">Share this message via clipboard</div>
                </div>
              </button>

              <button
                onClick={handleTwitterShare}
                className="w-full flex items-center space-x-4 p-4 hover:bg-gray-50 rounded-lg transition-colors border border-gray-100"
              >
                <TwitterIcon className="h-6 w-6 text-[#1DA1F2] flex-shrink-0" />
                <div className="text-left flex-1">
                  <div className="font-medium text-gray-900">Share on Twitter</div>
                  <div className="text-sm text-gray-500 mt-0.5">Tweet this message to your followers</div>
                </div>
              </button>

              <button
                onClick={handleTelegramShare}
                className="w-full flex items-center space-x-4 p-4 hover:bg-gray-50 rounded-lg transition-colors border border-gray-100"
              >
                <MessageCircle className="h-6 w-6 text-[#0088cc] flex-shrink-0" />
                <div className="text-left flex-1">
                  <div className="font-medium text-gray-900">Share on Telegram</div>
                  <div className="text-sm text-gray-500 mt-0.5">Send this message via Telegram</div>
                </div>
              </button>
            </div>

            <button
              onClick={() => setShowSharePopup(false)}
              className="mt-6 w-full py-2 text-sm text-gray-600 hover:text-gray-900 transition-colors"
            >
              Cancel
            </button>
          </div>
        </div>
      )}
    </div>
  );
};