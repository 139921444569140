import React from 'react';
import { Heart, GithubIcon, TwitterIcon, MessageCircle } from 'lucide-react';

export const Footer: React.FC = () => {
  return (
    <footer className="bg-white border-t">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          <div>
            <h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase">
              About
            </h3>
            <p className="mt-4 text-base text-gray-500">
              Spreading love and environmental awareness through blockchain technology.
              Every gift makes a difference. 🌱
            </p>
          </div>
          
          <div>
            <h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase">
              Quick Links
            </h3>
            <ul className="mt-4 space-y-4">
              <li>
                <a href="#" className="text-base text-gray-500 hover:text-green-500">
                  Home
                </a>
              </li>
              <li>
                <a href="#" className="text-base text-gray-500 hover:text-green-500">
                  Leaderboard
                </a>
              </li>
              <li>
                <a href="#" className="text-base text-gray-500 hover:text-green-500">
                  About
                </a>
              </li>
            </ul>
          </div>
          
          <div>
            <h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase">
              Connect With Us
            </h3>
            <div className="mt-4 flex space-x-6">
              <a href="#" className="text-gray-400 hover:text-green-500">
                <GithubIcon className="h-6 w-6" />
              </a>
              <a href="#" className="text-gray-400 hover:text-green-500">
                <TwitterIcon className="h-6 w-6" />
              </a>
              <a 
                href="https://t.me/GreenMessagez"
                target="_blank"
                rel="noopener noreferrer"
                className="text-gray-400 hover:text-green-500"
                title="Join our Telegram community"
              >
                <MessageCircle className="h-6 w-6" />
              </a>
            </div>
            <div className="mt-4">
              <a 
                href="https://www.producthunt.com/posts/greenmessage-send-messages-via-bnb?embed=true&utm_source=badge-featured&utm_medium=badge&utm_souce=badge-greenmessage&#0045;send&#0045;messages&#0045;via&#0045;bnb" 
                target="_blank" 
                rel="noopener noreferrer"
                className="flex-shrink-0"
              >
                <img 
                  src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=694180&theme=neutral" 
                  alt="GreenMessage: Send messages via BNB" 
                  width="250" 
                  height="54" 
                  className="h-[54px] w-[250px]"
                />
              </a>
            </div>
          </div>
        </div>
        
        <div className="mt-8 border-t border-gray-200 pt-8">
          <p className="text-base text-gray-400 text-center">
            Made with <Heart className="h-4 w-4 inline text-red-500" /> by the Green Message Team
          </p>
        </div>
        
        <div className="flex justify-between items-center">
          <div className="flex space-x-4">
            {/* Existing footer content */}
          </div>
          
          <div>
            <a 
              href="https://www.producthunt.com/posts/greenmessage-send-messages-via-bnb?embed=true&utm_source=badge-featured&utm_medium=badge&utm_souce=badge-greenmessage&#0045;send&#0045;messages&#0045;via&#0045;bnb" 
              target="_blank" 
              rel="noopener noreferrer"
              className="flex-shrink-0"
            >
              <img 
              />
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};