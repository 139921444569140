import React, { useState } from 'react';
import { Leaf, Home, Info, LineChart, X, MessageCircle } from 'lucide-react';
import { WalletConnect } from './WalletConnect';
import { ChainSelector } from './ChainSelector';
import { Transparency } from './Transparency';

interface HeaderProps {
  onNavigate: (view: 'home' | 'leaderboard' | 'about') => void;
  currentView: string;
}

export const Header: React.FC<HeaderProps> = ({ onNavigate, currentView }) => {
  const [showTransparency, setShowTransparency] = useState(false);

  const navItems = [
    { id: 'home', label: 'Home', icon: <Home className="h-5 w-5" /> },
    { id: 'leaderboard', label: 'Leaderboard', icon: <LineChart className="h-5 w-5" /> },
    { id: 'about', label: 'About', icon: <Info className="h-5 w-5" /> }
  ];

  return (
    <>
      <header className="bg-white shadow-sm sticky top-0 z-50">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-4">
          <div className="flex justify-between items-center">
            <div className="flex items-center space-x-2 cursor-pointer group" onClick={() => onNavigate('home')}>
              <Leaf className="h-8 w-8 text-green-500 group-hover:animate-bounce" />
              <span className="text-2xl font-semibold text-green-600">Green Message</span>
            </div>
            
            <div className="hidden md:flex items-center space-x-8">
              <ChainSelector />
              
              <nav className="flex space-x-8">
                {navItems.map((item) => (
                  <button
                    key={item.id}
                    onClick={() => onNavigate(item.id as 'home' | 'leaderboard' | 'about')}
                    className={`flex items-center space-x-2 text-${
                      currentView === item.id ? 'green-600 font-medium' : 'gray-600'
                    } hover:text-green-500 transition-colors`}
                  >
                    {item.icon}
                    <span>{item.label}</span>
                  </button>
                ))}
                <button
                  onClick={() => setShowTransparency(true)}
                  className="flex items-center space-x-2 text-gray-600 hover:text-green-500 transition-colors"
                >
                  <Leaf className="h-5 w-5" />
                  <span>Transparency</span>
                </button>
                <a
                  href="https://t.me/GreenMessagez"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="flex items-center space-x-2 text-gray-600 hover:text-green-500 transition-colors"
                >
                  <MessageCircle className="h-5 w-5" />
                  <span>Community</span>
                </a>
              </nav>

              <WalletConnect />
            </div>
          </div>
        </div>
      </header>

      {/* Transparency Modal */}
      {showTransparency && (
        <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center p-4">
          <div className="bg-white rounded-lg shadow-xl max-w-4xl w-full max-h-[90vh] overflow-y-auto relative">
            <button
              onClick={() => setShowTransparency(false)}
              className="absolute top-4 right-4 text-gray-500 hover:text-gray-700"
            >
              <X className="h-6 w-6" />
            </button>
            <Transparency />
          </div>
        </div>
      )}
    </>
  );
};