import React, { useState } from 'react';
import { Wallet, Loader2 } from 'lucide-react';
import { useStore } from '../store/useStore';

export const WalletConnect: React.FC = () => {
  const { isWalletConnected, currentChain, setWalletConnected, setConnectedAddress } = useStore();
  const [isConnecting, setIsConnecting] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const connectWallet = async () => {
    setIsConnecting(true);
    setError(null);

    try {
      if (currentChain === 'BNB') {
        if (!window.ethereum) {
          throw new Error('Please install MetaMask to connect your wallet');
        }
        const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
        setConnectedAddress(accounts[0]);
        setWalletConnected(true);
      } else {
        if (!window.solana) {
          throw new Error('Please install Phantom wallet to connect');
        }
        const resp = await window.solana.connect();
        setConnectedAddress(resp.publicKey.toString());
        setWalletConnected(true);
      }
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Failed to connect wallet');
      setWalletConnected(false);
      setConnectedAddress(null);
    } finally {
      setIsConnecting(false);
    }
  };

  return (
    <div className="relative">
      <button
        onClick={connectWallet}
        disabled={isConnecting}
        className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 disabled:opacity-50 disabled:cursor-not-allowed transition-colors"
      >
        {isConnecting ? (
          <Loader2 className="h-5 w-5 mr-2 animate-spin" />
        ) : (
          <Wallet className="h-5 w-5 mr-2" />
        )}
        {isWalletConnected ? 'Connected' : 'Connect Wallet'}
      </button>
      {error && (
        <div className="absolute top-full mt-2 w-64 p-2 text-sm text-red-600 bg-red-50 rounded-md border border-red-200">
          {error}
        </div>
      )}
    </div>
  );
};