import React, { useState } from 'react';
import { Send, Bug, Leaf, Loader2, Gift } from 'lucide-react';
import { useStore } from '../store/useStore';
import { sendGift } from '../utils/blockchain';
import { RECIPIENT_ADDRESS } from '../utils/constants';
import type { MessageTheme, Message } from '../types';

export const GiftForm: React.FC = () => {
  const { currentChain, isWalletConnected, addMessage, connectedAddress } = useStore();
  const [message, setMessage] = useState('');
  const [amount, setAmount] = useState('');
  const [theme, setTheme] = useState<MessageTheme>('butterfly');
  const [isAnonymous, setIsAnonymous] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [recipientAddress, setRecipientAddress] = useState('');
  const [giftType, setGiftType] = useState<'message' | 'birthday' | 'anniversary' | 'congratulations'>('message');
  const CUSTOM_RECIPIENT_FEE = 0.002; // Fee in BNB
  const MIN_AMOUNT = 0.0035;
  const PLATFORM_FEE = 0.02; // 2%

  const showSuccessNotification = () => {
    const notification = document.createElement('div');
    notification.className = 'fixed inset-0 flex items-center justify-center z-50';
    notification.innerHTML = `
      <div class="fixed inset-0 bg-black bg-opacity-50 transition-opacity"></div>
      <div class="bg-white rounded-xl p-8 max-w-sm mx-auto relative z-50 transform transition-all duration-300 scale-95 opacity-0 shadow-2xl">
        <div class="text-center">
          <div class="mx-auto flex items-center justify-center h-16 w-16 rounded-full bg-green-100 mb-6">
            <svg class="h-10 w-10 text-green-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>
          </div>
          <h3 class="text-2xl font-semibold text-gray-900 mb-3">
            Gift Sent Successfully! 🎉
          </h3>
          <p class="text-gray-500 mb-6">
            Thank you for spreading joy! Your gift will appear in the messages shortly.
          </p>
          <button onclick="this.closest('.fixed').remove()" 
            class="w-full py-3 px-4 text-sm font-medium text-white bg-green-600 hover:bg-green-700 rounded-lg transition-colors duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500">
            Continue
          </button>
        </div>
      </div>
    `;

    document.body.appendChild(notification);

    // Fade in and scale up
    requestAnimationFrame(() => {
      const modal = notification.querySelector('.bg-white') as HTMLElement;
      if (modal) {
        modal.style.transform = 'scale(1)';
        modal.style.opacity = '1';
      }
    });

    // Auto remove after 5 seconds
    setTimeout(() => {
      notification.remove();
    }, 5000);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError(null);

    if (!isWalletConnected) {
      setError('Please connect your wallet first');
      return;
    }

    if (!message.trim()) {
      setError('Please enter a message');
      return;
    }

    if (!amount || parseFloat(amount) < MIN_AMOUNT) {
      setError(`Minimum amount is ${MIN_AMOUNT} BNB`);
      return;
    }

    setIsLoading(true);
    try {
      const customRecipientFee = recipientAddress ? CUSTOM_RECIPIENT_FEE : 0;
      const totalAmount = parseFloat(amount) * (1 + PLATFORM_FEE) + customRecipientFee;
      
      const recipient = recipientAddress || RECIPIENT_ADDRESS[currentChain];
      const txHash = await sendGift(recipient, totalAmount);

      const newMessage = {
        id: txHash,
        sender: connectedAddress || '',
        recipient: recipientAddress || RECIPIENT_ADDRESS[currentChain],
        content: message,
        amount: totalAmount,
        chain: currentChain,
        timestamp: Date.now(),
        theme,
        giftType,
        isAnonymous,
        isPaid: true,
        likes: 0,
        likedBy: []
      } as Message;

      await addMessage(newMessage);

      setMessage('');
      setAmount('');
      showSuccessNotification();
    } catch (err) {
      console.error('Error sending gift:', err);
      setError(err instanceof Error ? err.message : 'Failed to send gift. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="bg-white rounded-lg shadow-lg p-6 max-w-5xl mx-auto w-full">
      <h2 className="text-xl font-semibold mb-4 text-gray-800">Send a Gift</h2>
      
      {error && (
        <div className="mb-4 p-3 bg-red-50 border border-red-200 text-red-600 rounded-md text-sm">
          {error}
        </div>
      )}

      <div className="space-y-4">
        <div>
          <label className="block text-sm font-medium text-gray-700">Message</label>
          <textarea
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500 transition-colors"
            rows={3}
            placeholder="Share your message of love..."
            disabled={isLoading}
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">
            Amount (BNB)
            <span className="text-sm text-gray-500 ml-1">
              (Min: {MIN_AMOUNT} BNB, +{PLATFORM_FEE * 100}% platform fee)
            </span>
          </label>
          <input
            type="number"
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500 transition-colors"
            placeholder={MIN_AMOUNT.toString()}
            min={MIN_AMOUNT}
            step="0.0001"
            disabled={isLoading}
          />
          {amount && (
            <p className="mt-1 text-sm text-gray-500">
              Total with fee: {(parseFloat(amount) * (1 + PLATFORM_FEE)).toFixed(4)} BNB
            </p>
          )}
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">Theme</label>
          <div className="flex space-x-4">
            <button
              type="button"
              onClick={() => setTheme('butterfly')}
              className={`flex items-center px-4 py-2 rounded-md transition-colors ${
                theme === 'butterfly' ? 'bg-green-100 text-green-700' : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
              }`}
              disabled={isLoading}
            >
              <Bug className={`h-5 w-5 mr-2 ${theme === 'butterfly' ? 'animate-bounce' : ''}`} />
              Butterfly
            </button>
            <button
              type="button"
              onClick={() => setTheme('leaves')}
              className={`flex items-center px-4 py-2 rounded-md transition-colors ${
                theme === 'leaves' ? 'bg-green-100 text-green-700' : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
              }`}
              disabled={isLoading}
            >
              <Leaf className={`h-5 w-5 mr-2 ${theme === 'leaves' ? 'animate-bounce' : ''}`} />
              Leaves
            </button>
          </div>
        </div>

        <div className="flex items-center">
          <input
            type="checkbox"
            id="anonymous"
            checked={isAnonymous}
            onChange={(e) => setIsAnonymous(e.target.checked)}
            className="h-4 w-4 text-green-600 focus:ring-green-500 border-gray-300 rounded transition-colors"
            disabled={isLoading}
          />
          <label htmlFor="anonymous" className="ml-2 block text-sm text-gray-700">
            Send anonymously
          </label>
        </div>

        <div className="space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">Gift Type</label>
            <div className="grid grid-cols-3 gap-2">
              {[
                { id: 'birthday', label: 'Birthday', icon: Gift },
                { id: 'anniversary', label: 'Anniversary', icon: Gift },
                { id: 'congratulations', label: 'Congratulations', icon: Gift }
              ].map(({ id, label, icon: Icon }) => (
                <button
                  key={id}
                  type="button"
                  onClick={() => setGiftType(id as 'birthday' | 'anniversary' | 'congratulations')}
                  className={`flex items-center justify-center p-2 rounded-md border text-sm ${
                    giftType === id 
                      ? 'border-green-500 bg-green-50 text-green-700' 
                      : 'border-gray-200 hover:border-gray-300'
                  }`}
                >
                  <Icon className="h-4 w-4 mr-1" />
                  <span className="truncate">{label}</span>
                </button>
              ))}
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">
              Custom Recipient Address
            </label>
            <input
              type="text"
              value={recipientAddress}
              onChange={(e) => setRecipientAddress(e.target.value)}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500"
              placeholder="0x... (optional)"
              disabled={isLoading}
            />
          </div>
        </div>

        <button
          type="submit"
          className="w-full flex justify-center items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 disabled:opacity-50 disabled:cursor-not-allowed transition-colors"
          disabled={isLoading || !isWalletConnected}
        >
          {isLoading ? (
            <>
              <Loader2 className="h-5 w-5 mr-2 animate-spin" />
              Sending...
            </>
          ) : (
            <>
              <Send className="h-5 w-5 mr-2" />
              Send Gift
            </>
          )}
        </button>
      </div>
    </form>
  );
};