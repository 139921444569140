import React from 'react';
import type { Chain } from '../types';

export const ChainSelector: React.FC = () => {
  const chain = { id: 'BNB' as Chain, name: 'BNB Chain', icon: '⚡' };

  return (
    <div className="flex space-x-2">
      <button
        className="flex items-center px-3 py-1.5 rounded-full text-sm font-medium bg-green-100 text-green-800"
      >
        <span className="mr-1">{chain.icon}</span>
        {chain.name}
      </button>
    </div>
  );
};