import React, { useEffect, useRef, useState } from 'react';
import { useStore } from '../store/useStore';
import { MessageItem } from './MessageItem';
import { ArrowDown, SortAsc, SortDesc, MessageCircle, UserCircle2, Info, ChevronDown } from 'lucide-react';
import { Tooltip } from 'react-tooltip';

export const ChatBox: React.FC = () => {
  const messages = useStore((state) => state.messages);
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('desc');
  const [filter, setFilter] = useState<'all' | 'anonymous'>('all');
  const [showScrollButton, setShowScrollButton] = useState(false);
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (sortOrder === 'desc') {
      messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [messages.length, sortOrder]);

  const handleScroll = () => {
    if (containerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = containerRef.current;
      setShowScrollButton(scrollHeight - scrollTop - clientHeight > 100);
    }
  };

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const filteredMessages = messages
    .filter(msg => {
      switch (filter) {
        case 'anonymous': return msg.isAnonymous;
        default: return true;
      }
    })
    .sort((a, b) => {
      const order = sortOrder === 'asc' ? 1 : -1;
      return (a.timestamp - b.timestamp) * order;
    });

  const sortedMessages = filteredMessages.sort((a, b) => {
    // First sort by likes (higher likes first)
    if (a.likes !== b.likes) {
      return (b.likes || 0) - (a.likes || 0);
    }
    
    // If likes are equal, sort by timestamp (newer first)
    return b.timestamp - a.timestamp;
  });

  return (
    <div className="flex flex-col bg-white rounded-lg shadow-lg p-2 sm:p-4">
      <div className="sticky top-0 bg-white z-10 space-y-2 sm:space-y-4 mb-2 sm:mb-4">
        <div className="flex items-center gap-2">
          <MessageCircle className="h-5 w-5 sm:h-6 sm:w-6 text-green-500" />
          <h2 className="text-lg sm:text-xl font-semibold text-gray-800">Global Chat</h2>
          <button 
            data-tooltip-id="chat-info" 
            className="text-gray-400 hover:text-green-500"
          >
            <Info className="h-3 w-3 sm:h-4 sm:w-4" />
          </button>
          <Tooltip id="chat-info" place="right" className="max-w-[250px] sm:max-w-xs">
            <div className="text-xs sm:text-sm">
              <p className="font-medium mb-1">Welcome to Global Chat!</p>
              <p>Share messages and connect with others around the world.</p>
            </div>
          </Tooltip>
        </div>
        
        <div className="flex flex-col sm:flex-row gap-2 sm:gap-3 sm:items-center">
          <div className="relative w-[140px] group">
            <select
              value={filter}
              onChange={(e: React.ChangeEvent<HTMLSelectElement>) => 
                setFilter(e.target.value as 'all' | 'anonymous')}
              className="w-full pl-8 pr-3 py-1 text-sm rounded-md border border-gray-200 focus:outline-none focus:ring-2 focus:ring-green-500 appearance-none cursor-pointer"
              aria-label="Filter messages"
            >
              <option value="all">
                All Messages
              </option>
              <option value="anonymous">
                Anonymous
              </option>
            </select>
            <UserCircle2 className="absolute left-2 top-1/2 transform -translate-y-1/2 h-4 w-4 text-gray-400 pointer-events-none" />
            <ChevronDown className="absolute right-2 top-1/2 transform -translate-y-1/2 h-3 w-3 text-gray-400 pointer-events-none" />
            
            <div className="absolute hidden group-hover:block left-0 bottom-full mb-2 w-48 p-2 bg-gray-800 text-white text-xs rounded-md shadow-lg">
              <p className="font-medium mb-1">Message Filter</p>
              <p className="text-gray-300">Choose to view all messages or only anonymous ones</p>
            </div>
          </div>

          <button
            onClick={() => setSortOrder(prev => prev === 'asc' ? 'desc' : 'asc')}
            className="p-1.5 sm:p-2 rounded-md hover:bg-gray-100 flex items-center gap-2"
            data-tooltip-id="sort-info"
          >
            {sortOrder === 'asc' ? 
              <SortAsc className="h-4 w-4 sm:h-5 sm:w-5 text-gray-600" /> : 
              <SortDesc className="h-4 w-4 sm:h-5 sm:w-5 text-gray-600" />
            }
            <span className="text-xs sm:text-sm text-gray-600">
              {sortOrder === 'asc' ? 'Oldest First' : 'Newest First'}
            </span>
          </button>
        </div>
      </div>

      <div 
        ref={containerRef}
        onScroll={handleScroll}
        className="flex-1 space-y-3 sm:space-y-4 h-[400px] sm:h-[600px] overflow-y-scroll [&::-webkit-scrollbar]:hidden [-ms-overflow-style:none] [scrollbar-width:none]"
      >
        {messages.length === 0 ? (
          <div className="text-center py-6 sm:py-8 space-y-2">
            <MessageCircle className="h-6 w-6 sm:h-8 sm:w-8 text-gray-300 mx-auto" />
            <p className="text-sm sm:text-base text-gray-500">No messages yet.</p>
            <p className="text-xs sm:text-sm text-gray-400">Be the first to spread some love!</p>
          </div>
        ) : (
          <>
            {sortedMessages.map((message, index) => (
              <MessageItem key={message.id} message={message} index={index} />
            ))}
            <div ref={messagesEndRef} />
          </>
        )}
      </div>

      {showScrollButton && (
        <button
          onClick={scrollToBottom}
          className="fixed bottom-4 sm:bottom-8 right-4 sm:right-8 p-2 sm:p-3 bg-green-500 text-white rounded-full shadow-lg hover:bg-green-600 transition-all transform hover:scale-110 group"
          title="Scroll to Bottom"
        >
          <ArrowDown className="h-5 w-5 group-hover:animate-bounce" />
        </button>
      )}
    </div>
  );
};