import { create } from 'zustand';
import { collection, addDoc, onSnapshot, query, orderBy, doc, runTransaction, deleteDoc } from 'firebase/firestore';
import type { Chain, Message, User } from '../types';
import { db } from '../config/firebase';
import { sendGift } from '../utils/blockchain';
import { RECIPIENT_ADDRESS } from '../utils/constants';

export type StoreState = {
  messages: Message[];
  currentChain: Chain;
  connectedAddress: string | null;
  users: Record<string, User>;
  isWalletConnected: boolean;
  setCurrentChain: (chain: Chain) => void;
  setConnectedAddress: (address: string | null) => void;
  addMessage: (message: Message) => Promise<void>;
  setWalletConnected: (connected: boolean) => void;
  likeMessage: (messageId: string, userAddress: string, chain: Chain) => Promise<string>;
  getLikesCount: (messageId: string) => Promise<number>;
  deleteMessage: (messageId: string) => Promise<void>;
  boostMessage: (id: string, amount: string) => Promise<boolean>;
  removeMessage: (messageId: string) => void;
};

const ADMIN_ADDRESS = '0xD0d7b2e7c46C28610EbA26b8B754cC3203109FAb';

export const useStore = create<StoreState>((set, get) => {
  // Set up real-time listener for messages
  const messagesQuery = query(
    collection(db, 'messages'),
    orderBy('timestamp', 'desc')
  );

  onSnapshot(messagesQuery, (snapshot) => {
    const messages = snapshot.docs.map(doc => ({
      ...doc.data(),
      id: doc.id,
    })) as Message[];
    
    // Sort messages with boosted ones first
    const sortedMessages = messages.sort((a, b) => {
      if ((a as Message & { boosted?: boolean }).boosted && !(b as Message & { boosted?: boolean }).boosted) return -1;
      if (!(a as Message & { boosted?: boolean }).boosted && (b as Message & { boosted?: boolean }).boosted) return 1;
      return b.timestamp - a.timestamp;
    });

    set({ messages: sortedMessages });
  });

  return {
    messages: [],
    currentChain: 'BNB',
    connectedAddress: null,
    users: {},
    isWalletConnected: false,
    
    setCurrentChain: (chain) => set({ currentChain: chain }),
    setConnectedAddress: (address) => set({ connectedAddress: address }),
    setWalletConnected: (connected) => set({ isWalletConnected: connected }),
    
    addMessage: async (message) => {
      if (message.id.startsWith('0x')) {
        await addDoc(collection(db, 'messages'), {
          ...message,
          isPaid: true,
          timestamp: Date.now(),
          likes: 0,
          likedBy: []
        });
      } else {
        set((state) => ({
          messages: [message, ...state.messages].slice(0, 100)
        }));
      }
    },
    
    likeMessage: async (messageId, userAddress, chain) => {
      try {
        const txHash = await sendGift(RECIPIENT_ADDRESS[chain], 0.001);
        const messageRef = doc(db, 'messages', messageId);
        await runTransaction(db, async (transaction) => {
          const messageDoc = await transaction.get(messageRef);
          if (!messageDoc.exists()) throw new Error('Message not found');
          const data = messageDoc.data();
          if (data.likedBy.includes(userAddress)) throw new Error('Already liked');
          transaction.update(messageRef, {
            likes: (data.likes || 0) + 1,
            likedBy: [...data.likedBy, userAddress]
          });
        });
        return txHash;
      } catch (error) {
        console.error('Error liking message:', error);
        throw error;
      }
    },

    deleteMessage: async (messageId) => {
      const { connectedAddress } = get();
      if (connectedAddress?.toLowerCase() !== ADMIN_ADDRESS.toLowerCase()) {
        throw new Error('Unauthorized');
      }
      const messageRef = doc(db, 'boosted-messages', messageId);
      await deleteDoc(messageRef);
      set(state => ({
        messages: state.messages.filter(msg => msg.id !== messageId)
      }));
    },

    getLikesCount: async (messageId) => {
      const messageRef = doc(db, 'boosted-messages', messageId);
      const messageDoc = await runTransaction(db, async (transaction) => {
        const doc = await transaction.get(messageRef);
        return doc.data()?.likes || 0;
      });
      return messageDoc;
    },

    boostMessage: async (id: string, amount: string): Promise<boolean> => {
      const { currentChain } = get();
      const messageRef = doc(db, 'messages', id);
      
      try {
        await sendGift(RECIPIENT_ADDRESS[currentChain as keyof typeof RECIPIENT_ADDRESS], parseFloat(amount));
        
        await runTransaction(db, async (transaction) => {
          const messageDoc = await transaction.get(messageRef);
          if (!messageDoc.exists()) throw new Error('Message not found');
          
          const boostExpiry = Date.now() + (30 * 60 * 1000); // 30 minutes
          
          transaction.update(messageRef, {
            boosted: true,
            boostExpiry,
            timeLeft: boostExpiry - Date.now(),
            boostTimestamp: Date.now()
          });
        });

        return true;
      } catch (error) {
        console.error('Error boosting message:', error);
        return false;
      }
    },

    removeMessage: (messageId) => 
      set((state) => ({
        messages: state.messages.filter(msg => msg.id !== messageId)
      })),
  };
});