import React, { useEffect, useState } from 'react';
import { Trophy, Award, Medal, Crown, Star, Filter, ArrowUpDown, Users } from 'lucide-react';
import { useStore } from '../store/useStore';
import { User } from '../types';
import { collection, query, orderBy, onSnapshot } from 'firebase/firestore';
import { db } from '../config/firebase';
import type { Message } from '../types/index';
import type { StoreState } from '../store/useStore';

type SortKey = 'totalSent' | 'messageCount' | 'totalLikes';

export const Leaderboard: React.FC = () => {
  const users = useStore((state) => state.users);
  const [sortKey, setSortKey] = useState<SortKey>('totalSent');
  const [timeFrame, setTimeFrame] = useState<'all' | 'week' | 'month'>('all');
  
  useEffect(() => {
    const messagesQuery = query(
      collection(db, 'messages'),
      orderBy('timestamp', 'desc')
    );

    const unsubscribe = onSnapshot(messagesQuery, (snapshot) => {
      const userStats = {} as Record<string, User>;

      snapshot.docs.forEach((doc) => {
        const message = doc.data() as Message;
        const sender = message.sender;
        const messageDate = new Date(message.timestamp);
        const now = new Date();
        
        // Skip if outside selected timeframe
        if (timeFrame === 'week' && messageDate < new Date(now.setDate(now.getDate() - 7))) return;
        if (timeFrame === 'month' && messageDate < new Date(now.setMonth(now.getMonth() - 1))) return;

        if (!userStats[sender]) {
          userStats[sender] = {
            totalSent: 0,
            messageCount: 0,
            badges: [],
            totalLikes: 0
          };
        }

        userStats[sender].totalSent += message.amount;
        userStats[sender].messageCount += 1;
        userStats[sender].totalLikes += message.likes || 0;
      });

      useStore.setState({ users: userStats as StoreState['users'] });
    });

    return () => unsubscribe();
  }, [timeFrame]);

  const sortedUsers = Object.entries(users)
    .sort(([, a], [, b]) => b[sortKey] - a[sortKey])
    .slice(0, 10);

  const getBadgeIcon = (index: number) => {
    switch (index) {
      case 0: return <Trophy className="h-6 w-6 text-yellow-400" aria-label="1st Place" />;
      case 1: return <Award className="h-6 w-6 text-gray-400" aria-label="2nd Place" />;
      case 2: return <Medal className="h-6 w-6 text-amber-600" aria-label="3rd Place" />;
      case 3: return <Crown className="h-5 w-5 text-purple-400" aria-label="4th Place" />;
      case 4: return <Star className="h-5 w-5 text-blue-400" aria-label="5th Place" />;
      default: return <Users className="h-4 w-4 text-gray-400" aria-label="Top 10" />;
    }
  };

  return (
    <div className="bg-white rounded-lg shadow-lg p-6 max-w-6xl mx-auto w-full">
      <div className="flex items-center justify-between mb-6">
        <h2 className="text-2xl font-semibold text-gray-800">Top Love Spreaders</h2>
        
        <div className="flex space-x-4">
          {/* Time Frame Filter */}
          <div className="relative">
            <select
              value={timeFrame}
              onChange={(e) => setTimeFrame(e.target.value as typeof timeFrame)}
              className="appearance-none bg-white border border-gray-200 rounded-lg px-4 py-2 pr-8 text-sm focus:outline-none focus:ring-2 focus:ring-green-500"
            >
              <option value="all">All Time</option>
              <option value="month">This Month</option>
              <option value="week">This Week</option>
            </select>
            <Filter className="absolute right-2 top-1/2 -translate-y-1/2 h-4 w-4 text-gray-400 pointer-events-none" />
          </div>

          {/* Sort By Filter */}
          <div className="relative">
            <select
              value={sortKey}
              onChange={(e) => setSortKey(e.target.value as SortKey)}
              className="appearance-none bg-white border border-gray-200 rounded-lg px-4 py-2 pr-8 text-sm focus:outline-none focus:ring-2 focus:ring-green-500"
            >
              <option value="totalSent">Total Sent</option>
              <option value="messageCount">Message Count</option>
              <option value="totalLikes">Total Likes</option>
            </select>
            <ArrowUpDown className="absolute right-2 top-1/2 -translate-y-1/2 h-4 w-4 text-gray-400 pointer-events-none" />
          </div>
        </div>
      </div>

      <div className="space-y-4">
        {sortedUsers.map(([address, user], index) => (
          <div 
            key={address} 
            className={`flex items-center justify-between p-4 rounded-lg transition-all duration-200 ${
              index === 0 ? 'bg-green-50 border border-green-100' :
              index === 1 ? 'bg-gray-50 border border-gray-100' :
              index === 2 ? 'bg-green-50 border border-green-100' :
              index % 2 === 0 ? 'bg-green-50 border border-green-100' : 
              'bg-gray-50 border border-gray-100'
            }`}
          >
            <div className="flex items-center space-x-4">
              <div className="flex-shrink-0 w-8">
                {getBadgeIcon(index)}
              </div>
              <div>
                <p className="font-medium text-gray-900">
                  {address.slice(0, 6)}...{address.slice(-4)}
                </p>
                <p className="text-sm text-gray-500">
                  {user.messageCount} messages • {user.totalLikes} likes
                </p>
              </div>
            </div>
            <div className="text-right">
              <p className="font-semibold text-green-600">
                {user.totalSent.toFixed(4)} total
              </p>
              <div className="flex space-x-1 mt-1">
                {user.badges.map((badge, i) => (
                  <span key={i} className="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-green-100 text-green-800">
                    {badge}
                  </span>
                ))}
              </div>
            </div>
          </div>
        ))}
        {sortedUsers.length === 0 && (
          <div className="text-center py-12 bg-gray-50 rounded-lg">
            <Users className="h-12 w-12 text-gray-300 mx-auto mb-3" />
            <p className="text-gray-500">No gifts sent yet. Be the first!</p>
          </div>
        )}
      </div>
    </div>
  );
};