import { ethers } from 'ethers';

export const sendGift = async (
  recipient: string,
  amount: number
): Promise<string> => {
  try {
    if (!window.ethereum) {
      throw new Error('Please install MetaMask to send gifts');
    }

    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = await provider.getSigner();
    
    // Format amount to 18 decimals and handle precision
    const formattedAmount = Number(amount).toFixed(18);
    const amountInWei = ethers.utils.parseEther(formattedAmount);

    // Create and send transaction
    const tx = await signer.sendTransaction({
      to: recipient,
      value: amountInWei
    });

    // Wait for transaction to be mined
    const receipt = await tx.wait();
    
    if (!receipt.transactionHash) {
      throw new Error('Transaction failed: No transaction hash received');
    }

    return receipt.transactionHash;
  } catch (error) {
    console.error('Transaction failed:', error);
    if (error instanceof Error) {
      throw new Error(error.message);
    } else {
      throw new Error('Failed to send gift. Please check your wallet and try again.');
    }
  }
};