import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAnalytics } from 'firebase/analytics';

const firebaseConfig = {
  apiKey: "AIzaSyB5CHYEX14kEcg1sPQ-xEQlnssDrJi1n1g",
  authDomain: "greenleaf-b85e2.firebaseapp.com",
  projectId: "greenleaf-b85e2",
  storageBucket: "greenleaf-b85e2.firebasestorage.app",
  messagingSenderId: "98092560237",
  appId: "1:98092560237:web:b5638accc042cbc498f464",
  measurementId: "G-YHH30V8HEE"
};

const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
export const db = getFirestore(app); 