import React, { useState, useEffect } from 'react';
import { Header } from './components/Header';
import { ChatBox } from './components/ChatBox';
import { GiftForm } from './components/GiftForm';
import { Leaderboard } from './components/Leaderboard';
import { About } from './components/About';
import { Footer } from './components/Footer';
import { SplashScreen } from './components/SplashScreen';

export const App: React.FC = () => {
  const [currentView, setCurrentView] = useState<'home' | 'leaderboard' | 'about'>('home');
  const [showSplash, setShowSplash] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowSplash(false);
    }, 4000); // 4 seconds to match SplashScreen duration

    return () => clearTimeout(timer);
  }, []);

  const renderContent = () => {
    switch (currentView) {
      case 'leaderboard':
        return <Leaderboard />;
      case 'about':
        return <About />;
      default:
        return (
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
            <ChatBox />
            <div className="space-y-8">
              <GiftForm />
              <Leaderboard />
            </div>
          </div>
        );
    }
  };

  return (
    <div>
      {showSplash ? (
        <SplashScreen />
      ) : (
        <>
          <Header onNavigate={setCurrentView} currentView={currentView} />
          <main className="flex-grow max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
            {renderContent()}
          </main>
          <Footer />
        </>
      )}
    </div>
  );
};

export default App;