import React from 'react';
import { Leaf, Heart } from 'lucide-react';

export const About: React.FC = () => {
  return (
    <div className="bg-white rounded-lg shadow-lg p-8">
      <h2 className="text-3xl font-semibold mb-6 text-gray-800">About Green Message Gifting</h2>
      
      <div className="space-y-8">
        <section className="mb-8">
          <h3 className="text-xl font-medium mb-4 flex items-center text-green-700">
            <Heart className="h-6 w-6 mr-2" />
            A Message from Bjorn
          </h3>
          <div className="prose text-gray-600 leading-relaxed space-y-4">
            <p>
              In today's fast-paced digital world, we often forget to express our appreciation and kindness to those around us. 
              Green Message was created with a simple yet powerful vision: to bring people closer together through meaningful, 
              heartfelt messages of support and gratitude.
            </p>
            <p>
              Whether you want to:
            </p>
            <ul className="list-disc list-inside space-y-2">
              <li>Thank a colleague who helped you through a tough project</li>
              <li>Express love to your family members</li>
              <li>Support a friend going through difficult times</li>
              <li>Remember someone who has passed away</li>
              <li>Appreciate your mentor or boss for their guidance</li>
              <li>Share encouragement with your roommate</li>
              <li>Simply spread positivity in our community</li>
            </ul>
            <p>
              Green Message provides a platform where kindness meets blockchain technology. Every message sent carries not just 
              words, but also a small token of appreciation that shows you truly care. In a world where people can sometimes 
              be harsh to each other, we believe kindness is the key to building stronger relationships and a better community.
            </p>
            <p className="font-medium text-green-700">
              Let's make the world a little greener and kinder, one message at a time.
            </p>
            <p className="text-sm italic">
              - Bjorn Louer, Creator of Green Message
            </p>
          </div>
        </section>

        <section>
          <h3 className="text-xl font-medium mb-4 flex items-center text-green-700">
            <Leaf className="h-6 w-6 mr-2" />
            How It Works
          </h3>
          <ul className="list-disc list-inside text-gray-600 space-y-2">
            <li>Send small amounts of BNB with your message</li>
            <li>Choose themed animations for your messages</li>
            <li>2% platform fee for maintenance and development</li>
            <li>1% of all gifts goes to environmental causes</li>
          </ul>
        </section>
      </div>
    </div>
  );
};