import React, { useState, useEffect } from 'react';
import { ExternalLink, Info, Copy, CheckCircle, Leaf, Wallet } from 'lucide-react';
import { Tooltip } from './Tooltip';
import { ethers } from 'ethers';

export const Transparency: React.FC = () => {
  const [copiedBNB, setCopiedBNB] = useState(false);
  const [balance, setBalance] = useState<string>('0');

  const addresses = {
    bnb: '0xD0d7b2e7c46C28610EbA26b8B754cC3203109FAb'
  };

  useEffect(() => {
    const fetchBalance = async () => {
      try {
        const provider = new ethers.providers.JsonRpcProvider('https://bsc-dataseed.binance.org');
        const balanceWei = await provider.getBalance(addresses.bnb);
        const balanceBNB = ethers.utils.formatEther(balanceWei);
        setBalance(Number(balanceBNB).toFixed(4));
      } catch (error) {
        console.error('Error fetching balance:', error);
      }
    };

    fetchBalance();
    const interval = setInterval(fetchBalance, 30000); // Update every 30 seconds
    return () => clearInterval(interval);
  }, []);

  const copyToClipboard = async (text: string) => {
    await navigator.clipboard.writeText(text);
    setCopiedBNB(true);
    setTimeout(() => {
      setCopiedBNB(false);
    }, 2000);
  };

  return (
    <div className="max-w-4xl mx-auto p-6">
      <div className="flex items-center space-x-3 mb-8">
        <Leaf className="h-8 w-8 text-green-500" />
        <h1 className="text-3xl font-bold text-gray-900">Transparency</h1>
      </div>

      <div className="space-y-6">
        <div className="bg-green-50 border-l-4 border-green-500 p-4 rounded-r">
          <div className="flex">
            <div className="flex-shrink-0">
              <Info className="h-5 w-5 text-green-500" />
            </div>
            <div className="ml-3">
              <p className="text-sm text-green-700">
                At greenmessage, we believe in full transparency. You can verify all income streams by checking these addresses.
              </p>
            </div>
          </div>
        </div>

        <div className="bg-white p-6 rounded-lg shadow-sm border border-gray-200 transform transition-all duration-200 hover:shadow-md hover:-translate-y-1">
          <div className="flex items-center justify-between mb-4">
            <div className="flex items-center space-x-2">
              <Leaf className="h-6 w-6 text-green-500" />
              <h2 className="text-xl font-semibold text-gray-800">BNB Chain</h2>
            </div>
            <div className="flex items-center space-x-2">
              <Wallet className="h-5 w-5 text-green-500" />
              <span className="text-green-600 font-medium">{balance} BNB</span>
            </div>
          </div>
          <div className="flex items-center space-x-2">
            <code className="bg-gray-100 p-2 rounded flex-1 font-mono text-sm">
              {addresses.bnb}
            </code>
            <Tooltip content="Copy address">
              <button 
                onClick={() => copyToClipboard(addresses.bnb)}
                className="text-gray-500 hover:text-green-600 transition-colors"
              >
                {copiedBNB ? <CheckCircle className="h-5 w-5 text-green-500" /> : <Copy className="h-5 w-5" />}
              </button>
            </Tooltip>
            <Tooltip content="View on BscScan">
              <a 
                href={`https://bscscan.com/address/${addresses.bnb}`}
                target="_blank"
                rel="noopener noreferrer"
                className="text-gray-500 hover:text-green-600 transition-colors"
              >
                <ExternalLink className="h-5 w-5" />
              </a>
            </Tooltip>
          </div>
        </div>
      </div>
    </div>
  );
};